* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*utilities*/

li {
  list-style-type: none;
}

body {
  /* margin: 0 2em; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #22272e;
  color: #a4aab9;
}

.section-title {
  text-align: center;
  /* margin: 2em 0 1em; */
  margin-bottom: 0.5em;
  text-decoration: underline;
}

/*Navbar*/
.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.item-navbar {
  font-size: 1.5rem;
  text-decoration: none;
  /* padding: 0.5rem 2rem; */
  color: #a4aab9;
}

.item-navbar-current,
.item-navbar:hover {
  text-decoration: none;
  color: #ec775c;
  transition: all 0.2s ease-in-out;
}

/*Home*/

#home,
#projects,
#contact-me,
footer {
  margin-bottom: 2em;
  padding: 0 2em;
}

#contact-me ul {
  max-width: 300px;
  display: flex;
  justify-content: space-around;
}
.main-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#pictureMR {
  max-width: 300px;
  height: auto;
  border: 2px solid #a4aab9;
  border-radius: 10px;
  margin: 2em;
  object-fit: cover;
}

.main-title img,
.main-title h1 {
  align-self: center;
}

.main-title h1 {
  color: white;
  font-size: 3rem;
  padding-bottom: 0.5rem;
}

.main-info {
  text-align: justify;
  margin-bottom: 2em;
}

.main-info p a:visited,
.main-info p a:active {
  color: white;
}

.linkToResume {
  padding: 1em 0;
  background-color: #a4aab9;
  text-decoration: none;
  color: black;
  border-radius: 10px;
  display: block;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.8px;

  margin: 2em 0;
}

.linkToResume:hover {
  transition: all 0.2s ease-in-out;
  background-color: #737885;
}

/*Home/Technologies*/
.technologies {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.technologies li {
  height: 100px;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-content: space-arounds;
  justify-content: space-arounds;
}

.technologies figure {
  padding: 0.5em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.technologies figure:hover {
  transition: 1s;
  transform: scale(1.2);
}

figcaption {
  font-size: 1.3em;
}

.postman {
  font-size: 0.75em;
}

/*Projects*/

.projects-main {
  display: flex;
  flex-direction: column;
}

.projects-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.projects-item:last-child {
  margin-bottom: 0;
}

.projects-item-pic {
  width: 100%;
  height: auto;
  background-color: #a4aab9;
  border: 2px solid #a4aab9;
  margin-top: 0.5em;
  /* margin-right: 2rem; */
  border-radius: 10px;
}

.projects-item-show h3 {
  /* margin-bottom: 0.5em; */
  color: white;
  font-size: 1.5rem;
}

.projects-item-text p,
.projects-item-text h3,
.projects-item-text h4 {
  text-align: justify;
  padding: 0.5em 0;
}

.projects-item-text-links {
  padding-top: 0.5em;
}

button {
  padding: 0.5rem 0.75rem;
  margin-right: 1em;
  background-color: rgb(126, 125, 125);
  background-color: #627288;
  background-color: #a4aab9;
  border: none;
  border-radius: 10px;
  width: 115px;
  height: 48px;
  font-weight: bold;
  letter-spacing: 0.08rem;
}

button:hover {
  transition: all 0.2s ease-in-out;
  background-color: #737885;
}

.projects-item h3 {
  color: white;
  font-size: 2rem;
  margin: 0;
}

.projects-item-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* padding: 0 1rem; */
}

/* contact-me */
#contact-me {
  margin-bottom: 0;
}

.contact-me-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-me-main ul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}
#contact-me-picture-container-buttons {
  margin: 0 1em;
}

#contact-me-text {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

#contact-me-text h3 {
  padding-bottom: 0.5rem;
}

#contact-me-text p {
  text-align: justify;
  padding: 0.5rem 0;
}

.footer {
  padding: 1em;
  display: flex;
  justify-content: center;
}

/*Media Queries */

@media (min-width: 500px) {
  nav {
    max-width: 400px;
    margin: 0 auto;
  }

  /* .nav-bar {
    display: grid;
    grid-template-columns: 10% 20% 20% 20% 10%;
  }

  .nav-bar li:nth-child(1) {
    grid-column: 2 / 3;
    justify-self: end;
  }

  .nav-bar li:nth-child(2) {
    grid-column: 3 / 4;
    justify-self: end;
  }

  .nav-bar li:nth-child(3) {
    grid-column: 4 / 6;
    justify-self: center;
  } */
  #home {
    display: grid;
    grid-template: auto auto auto/ 10% 15% 25% 25% 15% 10%;
  }

  #home .main-title {
    grid-area: pictureMR;
    grid-column: 2 / 4;
    grid-row: 1 / 3;
    align-self: start;
  }
  #pictureMR {
    margin: 2em 2em 0 0;
    max-width: 200px;
  }

  #home .main-info {
    grid-area: textBio;
    grid-column: 4 / 6;
    grid-row: 1 / 3;
    align-self: end;
  }

  #home .main-info p {
    margin-top: 1em;
  }

  #home .main-technologies {
    grid-area: technologies;
    /* background-color: aquamarine; */
    grid-column: 2 / 6;
    grid-row: 3 / 4;
  }

  /*Projects*/
  .projects-main {
    display: block;
  }

  .projects-item {
    display: grid;
    grid-template-columns: 10% 15% 25% 25% 15% 10%;
    grid-template-rows: auto auto;
  }
  .projects-item-show {
    display: flex;
    flex-direction: column;
    grid-column: 2 / 6;
    grid-row: 1;
  }

  .projects-item-show h3 {
    text-align: center;
  }

  .projects-item-text {
    grid-column: 2 / 6;
    grid-row: 2;
  }
}

@media (min-width: 1000px) {
  /* .nav-bar {
    margin: 0 16em;
    display: grid;
    grid-template-columns: auto 253px 253px 253px auto;
  } */

  #home {
    grid-template: auto auto auto/ auto 110px 270px 270px 110px auto;
  }

  .main-title {
    display: flex;
    flex-direction: column;
    justify-self: start;
  }

  #pictureMR {
    margin: 0;
    max-width: 300px;
  }

  .main-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main-info p {
    padding-top: 3.1em;
  }

  .linkToResume {
    margin: 3.5em 0 0;
    /* justify-self: end; */
  }

  .main-technologies h2 {
    margin-top: 1em;
  }

  /*Projects*/
  .projects-item {
    display: grid;
    grid-template-columns: auto 110px 270px 270px 110px auto;
    grid-template-rows: auto;
  }
  .projects-item-show {
    grid-column: 2 / 4;
    grid-row: 1;
  }
  .projects-item-text {
    grid-column: 4 / 6;
    grid-row: 1;
    padding: 2em 0 0 1em;
  }
}
